import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import appAPI from "../api/service";
import Loader from "../components/loader";
import { format } from "date-fns";

const Orders = ({ getUser }) => {
  const { id } = useParams();
  const nav = useNavigate();
  const [orders, setOrders] = useState(null);

  useEffect(() => {
    if (!id) return;
    async function getProject() {
      const res = await appAPI.getOrders(id);
      if (res?.error) {
        setOrders(false);
        return toast.error(res?.error);
      } else {
        if(res?.orders) {
          return setOrders(res?.orders);
        }
        setOrders(false);
      }
    }
    getProject();
  }, [id]);

  if (orders === null) return <Loader />;
  if (orders === false) return <Navigate to="/" />;
  return (
    <>
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div
            className="flex justify-start"
            onClick={() => {
              nav("/");
            }}
          >
            Назад
          </div>
        </nav>
      </header>
      <div className="flex min-h-full flex-1 flex-col px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Заявки по проекту {id}
          </h2>
        </div>

        {orders && (
          <ul role="list" className="divide-y divide-gray-100">
            {orders.map((order) => (
              <li key={order.id} className="flex gap-x-4 py-5">
                <div className="flex-auto">
                  <div className="flex items-baseline justify-between gap-x-4">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      Номер сделки: {order.order_id}
                    </p>
                    <p className="flex-none text-xs text-gray-600">
                      <time dateTime={new Date(order.transaction_date * 1000).toISOString()}>
                        {format(new Date(order.transaction_date * 1000), "HH:mm dd.MM.yyyy")}
                      </time>
                    </p>
                  </div>
                  <p className="mt-1 line-clamp-2 text-sm leading-6 text-gray-600">
                    Сумма сделки {order.amount} {order.currency}
                    <br />
                    {/*Статус сделки {order.status}
                    <br />*/}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default Orders;
