import { useState } from "react";
import toast from "react-hot-toast";
import { Navigate, useNavigate } from "react-router-dom";
import appAPI from "../api/service";
import { v4 } from "uuid";
import { max } from "date-fns/fp/max";

const Create = ({ userInfo }) => {
  const nav = useNavigate();
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [redirectURL, setRedirectURL] = useState("");
  const [redirectURLFail, setRedirectURLFail] = useState("")

  const [callbacSuccess, setCallbackSuccess] = useState("");
  const [callbacError, setCallbackError] = useState("");
  const [callbacAll, setCallbackAll] = useState("");
  const [maxLimit, setMaxLimit] = useState(0);
  const [outTime, setOutTime] = useState(20);

  const [moneyUrl, setMoneyUrl] = useState("");

  const handleSubmit = async () => {
    if (!name.trim() || !redirectURL.trim() || !url.trim())
      return toast.error("Введите все данные");
    const res = await appAPI.create({
      action: "addproject",
      projectName: name,
      projectUrl: url,
      secretKey: v4(),
      api: v4(),
      redirect_url: redirectURL,
      callbackSuccess: callbacSuccess,
      callbackError: callbacError,
      callbackAll: callbacAll,
      redirectUrlFail: redirectURLFail,
      out_time: outTime,
      max_limit: maxLimit,
      webhook_money: moneyUrl
    });
    if (res?.error) {
      return toast.error(res?.error);
    } else {
      toast.success("Успешно создано");
      return nav("/");
    }
  };

  if (userInfo === false) return <Navigate to="/auth" />;
  return (
    <>
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div
            className="flex  justify-end"
            onClick={() => {
              nav("/")
            }}
          >
            Назад
          </div>
        </nav>
      </header>

      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Создание
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-6" action="#" method="POST">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Название или ссылка на бота
              </label>
              <div className="mt-2">
                <input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  id="email"
                  name="email"
                  type="text"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  URL проекта
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setUrl(e.target.value)}
                  value={url}
                  id="password"
                  name="password"
                  type="text"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Редирект URL
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setRedirectURL(e.target.value)}
                  value={redirectURL}
                  id="password"
                  name="password"
                  type="text"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>


            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Редирект URL fail
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setRedirectURLFail(e.target.value)}
                  value={redirectURLFail}
                  id="password"
                  name="password"
                  type="text"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Время на отправку чека
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setOutTime(e.target.value)}
                  value={outTime}
                  id="password"
                  name="password"
                  type="number"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Лимит на сумму для отчет-запроса
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setMaxLimit(e.target.value)}
                  value={maxLimit}
                  id="password"
                  name="password"
                  type="number"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Webhook финансового отчета
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setMoneyUrl(e.target.value)}
                  value={moneyUrl}
                  id="password"
                  name="password"
                  type="number"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Успешный отчет URL
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setCallbackSuccess(e.target.value)}
                  value={callbacSuccess}
                  id="password"
                  name="password"
                  type="text"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Ошибка в отчете URL
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setCallbackError(e.target.value)}
                  value={callbacError}
                  id="password"
                  name="password"
                  type="text"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Все об отчете URL
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setCallbackAll(e.target.value)}
                  value={callbacAll}
                  id="password"
                  name="password"
                  type="text"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Создать
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Create;
