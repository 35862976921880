export const baseURL = "https://adpropayment.easycrypto.space/api/api.php"

const token = localStorage.getItem("ADMIN__SERVICE__JWT")

const appAPI = {
    async getUser() {
        try {
            const res = await fetch(`${baseURL}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("ADMIN__SERVICE__JWT")}`
                },
                body: JSON.stringify({ "action": "getuser", 'token': localStorage.getItem("ADMIN__SERVICE__JWT") })
            }).then(res => res.json())
            console.log(res)
            return res
        } catch (e) {
            console.log(e)
        }
    },
    async auth(data) {
        try {
            const res = await fetch(`${baseURL}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            }).then(res => res.json())
            return res
        } catch (e) {
            console.log(e)
        }
    },
    async create(data) {
        try {
            const res = await fetch(`${baseURL}`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("ADMIN__SERVICE__JWT")}`
                }
            }).then(res => res.json())
            return res
        } catch (e) {
            console.log(e)
        }
    },
    async delete(id) {
        try {
            const res = await fetch(`${baseURL}`, {
                method: "POST",
                body: JSON.stringify(id),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("ADMIN__SERVICE__JWT")}`
                }
            }).then(res => res.json())
            return res
        } catch (e) {
            console.log(e)
        }
    },
    async edit(data) {
        try {
            const res = await fetch(`${baseURL}/api/edit`, {
                method: "POST",
                body: JSON.stringify(data),
                mode: 'no-cors',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("ADMIN__SERVICE__JWT")}`
                }
            }).then(res => res.json())
            console.log(res)
            return res
        } catch (e) {
            console.log(e)
        }
    },
    async getProject(id) {
        try {
            const res = await fetch(`${baseURL}/api/project/${id}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("ADMIN__SERVICE__JWT")}`
                }
            }).then(res => res.json())
            console.log(res)
            return res
        } catch (e) {
            console.log(e)
        }
    },
    async getProjects(filters) {
        try {
            const res = await fetch(`${baseURL}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("ADMIN__SERVICE__JWT")}`
                },
                body: JSON.stringify(filters)
            }).then(res => res.json())
            return res
        } catch (e) {
            console.log(e)
        }
    },
    async getOrders(shop, order) {
        try {
            const res = await fetch(`${baseURL}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("ADMIN__SERVICE__JWT")}`
                },
                body: JSON.stringify({
                    "action": "orderinfo",
                    "shop_id": shop
                })
            }).then(res => res.json())
            return res
        } catch (e) {
            console.log(e)
        }
    },
}

export default appAPI