import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import appAPI from "../api/service";
import toast from "react-hot-toast";
import Loader from "../components/loader";
import { v4 } from "uuid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Main({ userInfo, getUser }) {
  const nav = useNavigate();
  const [projects, setProjects] = useState(null);
  
  const location = useLocation()

  const getQueryParams = (name) => {
    return new URLSearchParams(location.search).get(name)
  }

  const [filters, setFilters] = useState({
    id: getQueryParams("id") ? getQueryParams("id").split(',') : []
  })


  const [filtersBackend, setFiltersBackend] = useState(null)
  const [totalBalance, setTotalBalance] = useState(0);

  const [modal, setModal] = useState(null)

  async function getFilters() {
    const res = await appAPI.getProjects({
      action: "allprojects"
    });
    if (res?.error) {
      setFiltersBackend(false);
    } else {
      const pr = res?.projects;
      setFiltersBackend(pr.map(p => {
        return {
          id: p.id,
          name: p.name
        }
      }));
    }
  }

  async function getProjects(filters) {
    const requestBody = {
      action: "allprojects"
    };

    if (filters && filters.id.length > 0) {
      requestBody.searchParams = {id: filters.id.map(i => Number(i))}
    }
  
    try {
      const res = await appAPI.getProjects(requestBody);
      if (res.error) {
        setProjects(false);
        //return toast.error(res.error)
      } else {
        const projects = res?.projects;
        setProjects(projects);
        const total = projects.reduce((sum, project) => sum + Number(project.summ_project), 0);
        setTotalBalance(total);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  }

  useEffect(() => {
    
    getProjects(filters.id.length > 0 ? { id: filters.id } : null);
  }, [filters]);  
   
   useEffect(() => {
    getFilters()
  }, []); 

  const handleDelete = async (id) => {
    const res = await appAPI.delete({
      action: "deleteproject",
      projectId: id,
    });
    if (res.error) {
      return toast.error(res.error);
    } else {
      setProjects((prev) => prev.filter((p) => p.id !== id));
      const updatedTotal = projects.filter((p) => p.id !== id).reduce((sum, project) => sum + Number(project.summ_project), 0);
      setTotalBalance(updatedTotal);
      setModal(null)
      return toast.success("Успешно удалено");
    }
  };

  const updateQueryString = (filterId) => {
    const loc = new URLSearchParams(location.search)
    const initialFilters = getQueryParams("id") ? getQueryParams("id").split(',')  : []
    if(initialFilters.includes(filterId)) {
      const newFilters = initialFilters.filter(o => o != filterId)
      console.log(newFilters)
      if(newFilters.length == 0) {
        loc.delete("id")
      } else {
        loc.set("id", newFilters.join(","))
      }
      
    } else {
      loc.set("id", [...initialFilters, filterId].join(","))
    }

    nav({
      pathname: location.pathname,
      search: loc.toString(),
    });
  }

  const handleFiltersIdChange = (filterId) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      id: prevFilters.id.includes(filterId)
        ? prevFilters.id.filter(id => id !== filterId)
        : [...prevFilters.id, filterId],
    }));
    updateQueryString(filterId);
  };

  if (userInfo === false) return <Navigate to="/auth" />;
  if (userInfo.superuser == "1") return <Navigate to="/admin/main" />;
  if (projects === null || filtersBackend === null) return <Loader />;
  return (
    <div className="main__page">
      {modal && <div className="sm:rounded-lg modal" onClick={() => setModal(null)}>
        <div className="px-4 py-5 bg-white sm:p-6 modal_block" onClick={(e) => e.stopPropagation()}>
          <h3 className="text-base font-semibold leading-6 text-gray-900">Предупреждение</h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>
              Вы действительно хотите удалить проект {modal}?
            </p>
          </div>
          <div className="mt-5">
            <button
              type="button"
              onClick={() => {
                handleDelete(modal)
              }}
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              Удалить проект {modal}
            </button>
          </div>
        </div>
      </div>}


      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1"></div>
          <div className="hidden lg:flex lg:gap-x-12"></div>
          <div
            className="flex flex-1 justify-end"
            onClick={() => {
              localStorage.removeItem("ADMIN__SERVICE__JWT");
              window.location.reload();
            }}
          >
            Выйти с аккаунта
          </div>
        </nav>
      </header>
      {projects.length > 0 && (
        <div className="bg-white py-12 sm:py-16">
          <div className="mx-auto max-w-7xl px-3 lg:px-4">
            <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
              <div className="mx-auto flex max-w-xs flex-col gap-y-4">
                <dt className="text-base leading-7 text-gray-600">Баланс всех проектов</dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                  {totalBalance}$
                </dd>
              </div>
              <div className="mx-auto flex max-w-xs flex-col gap-y-4">
                <dt className="text-base leading-7 text-gray-600">Количество проектов</dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                  {projects.length}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      )}
      <button
        type="submit"
        onClick={() => nav("/create")}
        className="flex w-32 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Создать
      </button>

      <div className="mt-4 mb-4">
        <h2 className="text-xl mb-2">Фильтрация</h2>
        <div className="flex flex-wrap gap-2">
          {filtersBackend.map(id => (
            <button
              key={id}
              onClick={() => handleFiltersIdChange(id.id)}
              className={classNames(
                "px-3 py-2 rounded-md text-sm font-medium",
                filters.id.includes(id.id) ? "bg-indigo-600 text-white" : "bg-gray-200 text-gray-800 hover:bg-gray-300"
              )}
            >
              <span className="text-sm">ID: {id.id}</span> | {id.name}
            </button>
          ))}
        </div>
      </div>

      <ul role="list" className="divide-y divide-gray-100">
        {projects.length > 0 ? (
          projects.map((project) => (
            <li key={project.id} className="flex items-center justify-between gap-x-6 py-5">
              <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                  <p className="text-xxx font-semibold leading-6 text-gray-900">{project.name}</p>
                </div>
                <div className="flex items-start gap-x-3">
                  <p className="text-xxx font-semibold leading-6 text-gray-900">ID проекта: {project.id}</p>
                </div>


                



                <div className="mt-1 items-center gap-x-2 text-xx leading-5 text-gray-500">
                  <p className="truncate">Secret key: {project.secret_key}</p> 
                  <p className="truncate">Накопленная сумма: {Number(project.count_summ).toFixed(2)}</p>
                </div>

                <div className="text-xl font-semibold leading-6 text-gray-900 mt-4">Транзакции</div>
                {project.transactions && Object.keys(project.transactions).map(status => (
                  <div key={status}>
                    
                    {Object.keys(project.transactions[status]).map(currency => (
                      <>
                      <h4 className="ml-2"><strong>Статус:</strong> {project.transactions[status][currency].status}</h4>
                      
                      <div key={currency}>
                        <p><strong>Валюта:</strong> {currency}</p>
                        <p><strong>Количетсво со сервиса:</strong> {project.transactions[status][currency].total_amount} {currency.toLowerCase()}</p>
                        <p><strong>Количетсво на сервере:</strong> {project.transactions[status][currency].total_server_amount} {currency.toLowerCase()}</p>
                      </div></>
                    ))}
                  </div>
                ))}
              </div>

              
              <div className="flex flex-none items-center gap-x-4">
                <Menu as="div" className="relative flex-none">
                  <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => nav(`/edit/${project.id}`)}
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block px-3 py-1 text-sm leading-6 text-gray-900"
                            )}
                          >
                            Редактировать<span className="sr-only">, {project.name}</span>
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => setModal(project.id)}
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block px-3 py-1 text-sm leading-6 text-gray-900"
                            )}
                          >
                            Удалить<span className="sr-only">, {project.name}</span>
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => nav(`/orders/${project.id}`)}
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block px-3 py-1 text-sm leading-6 text-gray-900"
                            )}
                          >
                            Заявки<span className="sr-only">, {project.name}</span>
                          </div>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </li>
          ))
        ) : (
          <div className="not__found">У вас нет созданных проектов</div>
        )}
      </ul>
    </div>
  );
}

export default Main;
