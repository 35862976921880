import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import appAPI from "../api/service";
import Loader from "../components/loader";
import { v4 } from "uuid";

const SuperEdit = ({ userInfo }) => {
  const { id } = useParams();
  const nav = useNavigate();
  const [name, setName] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [url, setUrl] = useState("");
  const [redirectURL, setRedirectURL] = useState("");
  const [redirectURLFail, setRedirectURLFail] = useState("");
  const [project, setProject] = useState(null);
  const [api, setApi] = useState("");
  const [maxLimit, setMaxLimit] = useState(0);
  const [callbacSuccess, setCallbackSuccess] = useState("");
  const [callbacError, setCallbackError] = useState("");
  const [callbacAll, setCallbackAll] = useState("");
  const [outTime, setOutTime] = useState("");
  const [moneyUrl, setMoneyUrl] = useState("");

  const [manualKursUSDT, setManualKursUSDT] = useState("");
  const [manualKursRUB, setManualKursRUB] = useState("");
  const [kursUSDTRUB, setKursUSDTRUB] = useState("");
  const [kursRUBUSDT, setKursRUBUSDT] = useState("");
  const [requisitesCardRUB, setRequisitesCardRUB] = useState("");
  const [requisitesSBPRUB, setRequisitesSBPRUB] = useState("");
  const [requisitesUSDT, setRequisitesUSDT] = useState("");
  const [minRUB, setMinRUB] = useState("");
  const [maxRUB, setMaxRUB] = useState("");
  const [minUSDT, setMinUSDT] = useState("");
  const [maxUSDT, setMaxUSDT] = useState("");

  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (!id) return;
    async function getProject() {
      const res = await appAPI.getProjects({
        action: "allprojects"
      });
      if (res.error) {
        setProject(false);
        return toast.error(res.error);
      } else {
        if (res.projects.length > 0) {
          const data = res.projects.find((proj) => proj.id === id);
          setProject(data);
          setName(data.name);
          setSecretKey(data.secret_key);
          setRedirectURL(data.redirect_url);
          setRedirectURLFail(data.redirect_url_fail)
          setOutTime(data.out_time)
          setApi(data.api)
          setCallbackSuccess(data.callback_success);
          setCallbackError(data.callback_error);
          setCallbackAll(data.callback_all);
          setUrl(data.url);
          setMaxLimit(data.max_limit)
          setMoneyUrl(data.webhook_money)
          setManualKursUSDT(data.manual_kurs_usdt);
          setManualKursRUB(data.manual_kurs_rub);
          setKursUSDTRUB(data.kurs_usdt_rub);
          setKursRUBUSDT(data.kurs_rub_usdt);
          setRequisitesCardRUB(data.requisites_card_rub);
          setRequisitesSBPRUB(data.requisites_sbp_rub);
          setRequisitesUSDT(data.requisites_usdt);
          setMinRUB(data.min_rub);
          setMaxRUB(data.max_rub);
          setMinUSDT(data.min_usdt);
          setMaxUSDT(data.max_usdt);
        } else {
          toast.error("Проект не найден");
          return nav("/");
        }
      }
    }
    getProject();
  }, [id]);

  const handleSubmit = async () => {
    const res = await appAPI.create({
      action: "editsuperuserproject",
      "projectId": id,
      manual_kurs_usdt: manualKursUSDT,
      manual_kurs_rub: manualKursRUB,
      kurs_usdt_rub: kursUSDTRUB,
      kurs_rub_usdt: kursRUBUSDT,
      requisites_card_rub: requisitesCardRUB,
      requisites_sbp_rub: requisitesSBPRUB,
      requisites_usdt: requisitesUSDT,
      min_rub: minRUB,
      max_rub: maxRUB,
      min_usdt: minUSDT,
      max_usdt: maxUSDT,
      newProjectName: name,
      newProjectUrl: url,
      newSecretKey: secretKey,
      newRedirectUrl: redirectURL,
      callbackSuccess: callbacSuccess,
      callbackError: callbacError,
      callbackAll: callbacAll,
      api:api,
      newRedirectUrlFail: redirectURLFail,
      newOutTime: outTime,
      newMaxLimit: maxLimit,
      newWebhookMoney: moneyUrl
    });
    if (res.error) {
      return toast.error(res.error);
    } else {
      toast.success("Успешно сохранено");
      return nav("/admin/main");
    }
  };

  if (project === null) return <Loader />;
  if (project === false) return <Navigate to="/" />;
  if (userInfo.superuser != 1) return <Navigate to="/" />;
  return (
    <>

      {modal && <div className="sm:rounded-lg modal" onClick={() => setModal(false)}>
        <div className="px-4 py-5 bg-white sm:p-6 modal_block" onClick={(e) => e.stopPropagation()}>
          <h3 className="text-base font-semibold leading-6 text-gray-900">Предупреждение</h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>
              Вы действительно хотите поменять api key?
            </p>
          </div>
          <div className="mt-5">
            <button
              type="button"
              onClick={() => {
                setApi(v4())
                setModal(false)
                toast.success("Успешно изменено")
              }}
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              Поменять API KEY
            </button>
          </div>
        </div>
      </div>}
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div
            className="flex justify-start"
            onClick={() => {
              nav("/");
            }}
          >
            Назад
          </div>
        </nav>
      </header>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto w-full">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Редактирование параметров
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto w-full">
          <div className="space-y-6" action="#" method="POST">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Название или ссылка на бота
              </label>
              <div className="mt-2">
                <input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  id="email"
                  name="email"
                  type="text"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  URL проекта
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setUrl(e.target.value)}
                  value={url}
                  id="password"
                  name="password"
                  type="text"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Редирект URL
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setRedirectURL(e.target.value)}
                  value={redirectURL}
                  id="password"
                  name="password"
                  type="text"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Редирект URL fail
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setRedirectURLFail(e.target.value)}
                  value={redirectURLFail}
                  id="password"
                  name="password"
                  type="text"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Время на отправку чека
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setOutTime(e.target.value)}
                  value={outTime}
                  id="password"
                  name="password"
                  type="number"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Лимит на сумму для отчет-запроса
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setMaxLimit(e.target.value)}
                  value={maxLimit}
                  id="password"
                  name="password"
                  type="number"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Webhook финансового отчета
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setMoneyUrl(e.target.value)}
                  value={moneyUrl}
                  id="password"
                  name="password"
                  type="number"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  API KEY 
                </label>
              </div>
              <div className="mt-2">
                <input
                  value={api}
                  id="password"
                  name="password"
                  type="text"
                  required
                  readOnly
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              <div onClick={(e) => setModal(true)} className="w-full flex h-10 hover:bg-neutral-300 border-0 ring-1 ring-inset ring-gray-300 mt-2  rounded-md items-center justify-center">
                Изменить API KEY
              </div>
            </div>


            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Успешный отчет URL
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setCallbackSuccess(e.target.value)}
                  value={callbacSuccess}
                  id="password"
                  name="password"
                  type="text"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Ошибка в отчете URL
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setCallbackError(e.target.value)}
                  value={callbacError}
                  id="password"
                  name="password"
                  type="text"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Все об отчете URL
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setCallbackAll(e.target.value)}
                  value={callbacAll}
                  id="password"
                  name="password"
                  type="text"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <h6 className="text-2xl font-bold text-center">Настройки супер-админа</h6>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Ручной курс USDT
              </label>
              <div className="mt-2">
                <input
                  onChange={(e) => setManualKursUSDT(e.target.value)}
                  value={manualKursUSDT}
                  id="email"
                  name="email"
                  type="text"
                  
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                 Ручной курс RUB
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setManualKursRUB(e.target.value)}
                  value={manualKursRUB}
                  id="password"
                  name="password"
                  type="text"
                  
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                Курс USDT к RUB
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setKursUSDTRUB(e.target.value)}
                  value={kursUSDTRUB}
                  id="password"
                  name="password"
                  type="text"
                  
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Курс RUB к USDT
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setKursRUBUSDT(e.target.value)}
                  value={kursRUBUSDT}
                  id="password"
                  name="password"
                  type="text"
                  
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Реквизиты карты RUB
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setRequisitesCardRUB(e.target.value)}
                  value={requisitesCardRUB}
                  id="password"
                  name="password"
                  type="text"
                  
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>


            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Реквизиты карты USDT
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setRequisitesUSDT(e.target.value)}
                  value={requisitesUSDT}
                  id="password"
                  name="password"
                  type="text"
                  
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Реквизиты карты СПБ RUB
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setRequisitesSBPRUB(e.target.value)}
                  value={requisitesSBPRUB}
                  id="password"
                  name="password"
                  type="text"
                  
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Минимальна сумма в RUB
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setMinRUB(e.target.value)}
                  value={minRUB}
                  id="password"
                  name="password"
                  type="text"
                  
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Максимальная сумма в RUB
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setMaxRUB(e.target.value)}
                  value={maxRUB}
                  id="password"
                  name="password"
                  type="text"
                  
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>


            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Минимальна сумма в USDT
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setMinUSDT(e.target.value)}
                  value={minUSDT}
                  id="password"
                  name="password"
                  type="text"
                  
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>


            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Максимальная сумма в RUB
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setMaxUSDT(e.target.value)}
                  value={maxUSDT}
                  id="password"
                  name="password"
                  type="text"
                  
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Сохранить
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default  SuperEdit;
