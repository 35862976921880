import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Navigate, useNavigate } from "react-router-dom";
import appAPI from "../api/service";
import toast from "react-hot-toast";
import Loader from "../components/loader";
import { v4 } from "uuid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function SuperMain({ userInfo, getUser }) {
  const nav = useNavigate();
  const [projects, setProjects] = useState(null);
  const [totalBalance, setTotalBalance] = useState(0);

  const [modal, setModal] = useState(null)

  async function getProjects() {
    const res = await appAPI.getProjects({
      action: "allprojects"
    });
    if (res.error) {
      setProjects(false);
      //return toast.error(res.error)
    } else {
      const projects = res?.projects;
      setProjects(projects);
      const total = projects.reduce((sum, project) => sum + Number(project.summ_project), 0);
      setTotalBalance(total);
    }
  }

  useEffect(() => {
    getProjects();
  }, []);

  const handleDelete = async (id) => {
    const res = await appAPI.delete({
      action: "deleteproject",
      projectId: id,
    });
    if (res.error) {
      return toast.error(res.error);
    } else {
      setProjects((prev) => prev.filter((p) => p.id !== id));
      const updatedTotal = projects.filter((p) => p.id !== id).reduce((sum, project) => sum + Number(project.summ_project), 0);
      setTotalBalance(updatedTotal);
      setModal(null)
      return toast.success("Успешно удалено");
    }
  };

  if (userInfo === false) return <Navigate to="/auth" />;
  if(userInfo.superuser != 1) return <Navigate to="/" />;
  if (projects === null) return <Loader />;
  return (
    <div className="main__page">
      {modal && <div className="sm:rounded-lg modal" onClick={() => setModal(null)}>
        <div className="px-4 py-5 bg-white sm:p-6 modal_block" onClick={(e) => e.stopPropagation()}>
          <h3 className="text-base font-semibold leading-6 text-gray-900">Предупреждение</h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>
              Вы действительно хотите удалить проект {modal}?
            </p>
          </div>
          <div className="mt-5">
            <button
              type="button"
              onClick={() => {
                handleDelete(modal)
              }}
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              Удалить проект {modal}
            </button>
          </div>
        </div>
      </div>}


      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1"></div>
          <div className="hidden lg:flex lg:gap-x-12"></div>
          <div
            className="flex flex-1 justify-end"
            onClick={() => {
              localStorage.removeItem("ADMIN__SERVICE__JWT");
              window.location.reload();
            }}
          >
            Выйти с аккаунта
          </div>
        </nav>
      </header>
      {projects.length > 0 && (
        <div className="bg-white py-12 sm:py-16">
          <div className="mx-auto max-w-7xl px-3 lg:px-4">
            <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
              <div className="mx-auto flex max-w-xs flex-col gap-y-4">
                <dt className="text-base leading-7 text-gray-600">Количество проектов</dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                  {projects.length}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      )}
      <ul role="list" className="divide-y divide-gray-100">
        {projects.length > 0 ? (
          projects.map((project) => (
            <li key={project.id} className="flex items-center justify-between gap-x-6 py-5">
              <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                  <p className="text-xxx font-semibold leading-6 text-gray-900">{project.name}</p>
                </div>
                
                <div className="flex items-start gap-x-3">
                  <p className="text-xxx font-semibold leading-6 text-gray-900">Владелец магазина: {project.id}</p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xx leading-5 text-gray-500">
                  <p className="truncate">Secret key: {project.secret_key}</p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xx leading-5 text-gray-500">
                  <p className="truncate">Накопленная сумма: {Number(project.count_summ).toFixed(2)}</p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xx leading-5 text-gray-500">
                  <p className="truncate">Ручной курс USDT: {project.manual_kurs_usdt}</p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xx leading-5 text-gray-500">
                  <p className="truncate">Ручной курс RUB: {project.manual_kurs_rub}</p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xx leading-5 text-gray-500">
                  <p className="truncate">Курс USDT к RUB: {project.kurs_usdt_rub}</p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xx leading-5 text-gray-500">
                <p className="truncate">Курс RUB к USDT: {project.kurs_rub_usdt}</p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xx leading-5 text-gray-500">
                  <p className="truncate">Реквизиты карты RUB: {project.requisites_card_rub}</p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xx leading-5 text-gray-500">
                  <p className="truncate">Реквизиты карты USDT: {project.requisites_usdt}</p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xx leading-5 text-gray-500">
                  <p className="truncate">Реквизиты карты СПБ RUB: {project.requisites_sbp_rub}</p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xx leading-5 text-gray-500">
                  <p className="truncate">Минимальна сумма в RUB: {project.min_rub}</p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xx leading-5 text-gray-500">
                <p className="truncate">Максимальная сумма в RUB: {project.max_rub}</p>
                </div>

                <div className="mt-1 flex items-center gap-x-2 text-xx leading-5 text-gray-500">
                  <p className="truncate">Минимальна сумма в USDT: {project.min_usdt}</p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xx leading-5 text-gray-500">
                <p className="truncate">Максимальная сумма в USDT: {project.max_usdt}</p>
                </div>
              </div>

              
              <div className="flex flex-none items-center gap-x-4">
                <Menu as="div" className="relative flex-none">
                  <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => nav(`/admin/edit/${project.id}`)}
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block px-3 py-1 text-sm leading-6 text-gray-900"
                            )}
                          >
                            Редактировать<span className="sr-only">, {project.name}</span>
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => setModal(project.id)}
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block px-3 py-1 text-sm leading-6 text-gray-900"
                            )}
                          >
                            Удалить<span className="sr-only">, {project.name}</span>
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => nav(`/orders/${project.id}`)}
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block px-3 py-1 text-sm leading-6 text-gray-900"
                            )}
                          >
                            Заявки<span className="sr-only">, {project.name}</span>
                          </div>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </li>
          ))
        ) : (
          <div className="not__found">У вас нет созданных проектов</div>
        )}
      </ul>
    </div>
  );
}

export default SuperMain;
