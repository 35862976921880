import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Navigate, Route, Routes } from "react-router-dom";
import Auth from "./pages/auth";
import Main from "./pages/main";
import Create from "./pages/create";
import Edit from "./pages/edit";
import appAPI from "./api/service";
import Loader from "./components/loader";
import Orders from "./pages/orders";
import SuperMain from "./pages/superadmin";
import SuperEdit from "./pages/superedit";

function App() {
  const [userInfo,setUserInfo] = useState(null)

  async function getUser() {
    const res = await appAPI.getUser()
    if(res.error) {
      return setUserInfo(false)
    } else {
      setUserInfo(res)
    }
  }

  useEffect(() => {
    getUser()
  },[])


  if(userInfo === null) return <Loader />

  console.log(userInfo)
  return (
    <div className={`app`}>
      <Toaster />
      <Routes>
        <Route path="/" element={<Main userInfo={userInfo} getUser={getUser}/>}/>
        <Route path="/admin/main" element={<SuperMain userInfo={userInfo} getUser={getUser}/>}/>
        <Route path="/auth" element={<Auth userInfo={userInfo} setUserInfo={setUserInfo} getUser={getUser}/>}/>
        <Route path="/create" element={<Create userInfo={userInfo} getUser={getUser}/>}/>
        <Route path="/edit/:id" element={<Edit userInfo={userInfo} getUser={getUser}/>}/>
        <Route path="/admin/edit/:id" element={<SuperEdit userInfo={userInfo} getUser={getUser}/>}/>
        <Route path="/orders/:id" element={<Orders userInfo={userInfo} getUser={getUser}/>}/>
        <Route path="/*" element={<Navigate to="/"/>}/>
      </Routes>
    </div>
  );
}

export default App;
